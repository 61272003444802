/* eslint-disable jsx-a11y/label-has-associated-control */
import { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { Form, json, MetaFunction, redirect, useActionData } from "@remix-run/react";
import { useEffect } from "react";
import { z } from "zod";
import container from "~/.server/container";
import Symbols from "~/.server/Symbols";
import IGetOperatorLogin from "~/.server/useCase/operator_login/IGetOperatorLogin";
import ILoginOperator from "~/.server/useCase/operator_login/ILoginOperator";
import { operator_cookie } from "~/cookies.server";
import Toast from "~/shared/components/Toaster/Toast";
import Toaster from "~/shared/components/Toaster/Toaster";
import useToaster from "~/shared/components/Toaster/useToaster";
import KeyIcon from "~/shared/icons/KeyIcon";
import PersonIcon from "~/shared/icons/PersonIcon";

const formValidator = z.object({
    username: z.string(),
    password: z.string(),
});

export const meta: MetaFunction = () => {
    return [
        {
            title: "Login - Pythia"
        }
    ];
}

export async function loader({ request }: LoaderFunctionArgs) {
    const cookieHeader = request.headers.get("Cookie");
    const session_id = await operator_cookie.parse(cookieHeader) as string | null;

    if (!session_id) {
        return null;
    }

    const operatorOrNone = await container.get<IGetOperatorLogin>(Symbols.UseCase.operator_login.GetOperatorLogin).execute({ session_id });

    if (operatorOrNone.isSuccess()) {
        return redirect("/dashboard/");
    }
    return null;
}

export async function action(args: ActionFunctionArgs) {
    const body = Object.fromEntries(await args.request.formData());
    const { username, password } = formValidator.parse(body);

    const loginOrError = await container.get<ILoginOperator>(Symbols.UseCase.operator_login.LoginOperator).execute({ username, password });

    if (loginOrError.isFailure()) {
        return json(Toast.fromResult(loginOrError), { status: 401 });
    }

    const ls = loginOrError.unwrap();

    return redirect("/dashboard/", {
        headers: {
            "Set-Cookie": await operator_cookie.serialize(ls._id, { expires: ls.expires_at }),
        }
    });
}

export default function Login() {
    const data = useActionData<typeof action>();
    const { setToast } = useToaster();

    useEffect(() => { data && setToast(data) }, [data, setToast]);

    return <div className="hero bg-base-200 min-h-screen">
        <Toaster />
        <div className="hero-content flex-col lg:flex-row-reverse">
            <div className="text-center lg:text-left">
                <h1 className="text-5xl font-bold">Login now!</h1>
                <p className="py-6 max-w-[350px]">
                    Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem
                    quasi. In deleniti eaque aut repudiandae et a id nisi.
                </p>
            </div>
            <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl">
                <Form method="post" className="card-body">
                    <h1 className="text-2xl font-bold mb-4">Login</h1>
                    <label className="input input-bordered flex items-center gap-2 mb-4" >
                        <PersonIcon />
                        <input name="username" type="text" className="grow" placeholder="Username" />
                    </label>
                    <label className="input input-bordered flex items-center gap-2 mb-4"  >
                        <KeyIcon />
                        <input name="password" type="password" className="grow" placeholder="••••••" />
                    </label>
                    <div className="flex justify-between items-center">
                        <button type="submit" className="btn btn-primary hover:cursor-pointer w-full">Login</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
}